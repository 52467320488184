import Swiper from 'swiper';
import { Navigation, Scrollbar } from 'swiper/modules';

const categoryElements = document.querySelectorAll(
    '.js-products-slider-container'
);

if (categoryElements) {
    categoryElements.forEach(function (category, index) {
        category.classList.add(`js-products-slider-container-${index}`);

        let categoryClass = document.querySelectorAll(
            `.js-products-slider-container-${index} .js-change-products-slider`
        );

        categoryClass.forEach(function (item) {
            item.addEventListener('click', function (event) {
                const termTagId = event.target.getAttribute('data-term-tag-id');
                const termId = event.target.getAttribute('data-term-id');
                const containerId =
                    event.target.getAttribute('data-container-id');

                document
                    .querySelectorAll(
                        `.js-products-slider-container-${index} .js-change-products-slider`
                    )
                    .forEach(function (item) {
                        item.classList.remove('-active');
                    });

                this.classList.toggle('-active');
                handleForm(termId, termTagId, containerId);
            });
        });
    });
}

function handleForm(termId, termTagId, containerId) {
    var formData = new FormData();

    formData.append('action', 'mda_get_products_slider_ajax');
    formData.append('term_id', termId);
    formData.append('term_tag_id', termTagId);
    formData.append('container_id', containerId);

    var xhr = new XMLHttpRequest();
    xhr.open('POST', ajax.url);

    xhr.onloadstart = function () {};

    xhr.onload = function () {
        if (xhr.status === 200) {
            const productsContainer = document.querySelector(`#${containerId}`);

            if (productsContainer) {
                productsContainer.innerHTML = xhr.responseText;
                productsSlider();
            }
        } else {
            console.log('Błąd zapytania: ' + xhr.status);
        }
    };

    xhr.onloadend = function () {};

    xhr.send(formData);
}

function productsSlider() {
    const sliderElements = document.querySelectorAll(`.js-products-slider`);

    sliderElements.forEach((slider, index) => {
        slider.classList.add('js-product-slider-' + index);

        let sliderSelector = document.querySelector(
            `.js-product-slider-${index} .swiper`
        );
        let prevButtonSelector = document.querySelector(
            `.js-product-slider-${index} .swiper-button-prev`
        );
        let nextButtonSelector = document.querySelector(
            `.js-product-slider-${index} .swiper-button-next`
        );
        let scrollbarSelector = document.querySelector(
            `.js-product-slider-${index} .swiper-scrollbar`
        );

        const swiper = new Swiper(sliderSelector, {
            modules: [Navigation, Scrollbar],
            slidesPerView: 4,
            spaceBetween: 8,
            scrollbar: {
                el: scrollbarSelector,
                draggable: false,
            },
            navigation: {
                prevEl: prevButtonSelector,
                nextEl: nextButtonSelector,
            },
            breakpoints: {
                1500:{
                    slidesPerView: 4
                },
                1200: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 2,
                },
                320: {
                    slidesPerView: 1.1,
                },
            },
        });
    });
}

productsSlider();
